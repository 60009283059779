import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, EffectFade, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/effect-fade';
import './UHTHealthcare.css';

function UHTHealthcare() {

    const [isDesktop, setIsDesktop] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 980);  // You can adjust the width threshold as needed
    };

    handleResize();  // Check initial screen size
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize); // Clean up on unmount
  }, []);


  return (
    <>
        {/* <section className='space-div-block'></section> */}

        <section className='UHTHealthcare'>
            <div className='container'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <h1 className='healthcare-title' data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="300">Unlocking a New Era in Healthcare</h1>
                                </div>
                           </div>

                           <div className='row align-items-center'>
                                <div className='col-md-12 text-center'>
                                <Swiper
                                    spaceBetween={0}
                                    slidesPerView={1}
                                    effect="fade"
                                    fadeEffect={{ crossFade: true }} // Smooth fade effect
                                    navigation={{
                                      nextEl: '.custom-next-healthacare',
                                      prevEl: '.custom-prev-healthacare',
                                    }}
                                    autoplay={{
                                      delay: 3000, // Auto slide every 3 seconds
                                      disableOnInteraction: false,
                                    }}
                                    modules={[Navigation, EffectFade, Autoplay]}
                                    loop={true}
                                    speed={1000} // Adjust speed for smoother fade transitions
                                    className="custom-swiper-healthacare"
                                    >
                                    <SwiperSlide className='grey-box'>
                                        <div className='row align-items-center'>
                                            <div className='col-md-5 text-center'>
                                                <img src={require('../images/slider-Healthcare-1.jpg')} className="img-fluid" alt=""  data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="500"/>
                                            </div>
                                            <div className='col-md-7 px-5'>
                                                <h2 className='healthcare-subtitle' data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="500">Personalized AI Health Insights at Scale</h2>
                                                <p className='content-text mb-0' data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="600">UHT’s AI analyzes millions of daily data points, transforming raw health data into predictive insights that optimize wellness, prevent disease, and deliver hyper-personalized healthcare.</p>
                                            </div>
                                        </div>
                                    </SwiperSlide>

                                    <SwiperSlide className='grey-box'>
                                        <div className='row align-items-center'>
                                            <div className='col-md-5 text-center'>
                                                <img src={require('../images/slider-Healthcare-2.jpg')} className="img-fluid" alt=""  data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="500"/>
                                            </div>
                                            <div className='col-md-7 px-5'>
                                                <h2 className='healthcare-subtitle' data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="500">The Next Era of Wearable Intelligence</h2>
                                                <p className='content-text mb-0' data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="600">As wearable health tech evolves, UHT’s AI harnesses real-time biometrics to detect trends, improve precision care, and drive proactive well-being.</p>
                                            </div>
                                        </div>
                                    </SwiperSlide>

                                    <SwiperSlide className='grey-box'>
                                        <div className='row align-items-center'>
                                            <div className='col-md-5 text-center'>
                                                <img src={require('../images/slider-Healthcare-3.jpg')} className="img-fluid" alt=""  data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="500"/>
                                            </div>
                                            <div className='col-md-7 px-5'>
                                                <h2 className='healthcare-subtitle' data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="500">AI-Driven Chronic Disease Prevention</h2>
                                                <p className='content-text mb-0' data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="600">Chronic diseases are the world’s leading health challenge—UHT’s AI proactively identifies risks, tailors interventions, and empowers users to stay ahead of illness.</p>
                                            </div>
                                        </div>
                                    </SwiperSlide>

                                    <SwiperSlide className='grey-box'>
                                        <div className='row align-items-center'>
                                            <div className='col-md-5 text-center'>
                                                <img src={require('../images/slider-Healthcare-4.jpg')} className="img-fluid" alt=""  data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="500"/>
                                            </div>
                                            <div className='col-md-7 px-5'>
                                                <h2 className='healthcare-subtitle' data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="500">Fueling the Future of AI in Healthcare Research</h2>
                                                <p className='content-text mb-0' data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="600">By connecting anonymized data to cutting-edge AI research, UHT accelerates biomedical breakthroughs while ensuring users stay in control and get rewarded for contributing to innovation.</p>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                
                                    {/* Additional SwiperSlide components can go here */}

                                    <div className="custom-prev-healthacare"><i class="bi bi-arrow-left"></i></div>
                                    <div className="custom-next-healthacare"><i class="bi bi-arrow-right"></i></div>
                                    </Swiper>
                                </div>
                           </div>

                           
            </div>
        </section>
    </>
  );
}


export default UHTHealthcare;
