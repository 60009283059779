import React, { useState, useEffect } from 'react';

import './UHTVision.css';

function UHTVision() {

    const [isDesktop, setIsDesktop] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 980);  // You can adjust the width threshold as needed
    };

    handleResize();  // Check initial screen size
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize); // Clean up on unmount
  }, []);

  return (
    <>
        <section className='space-div' id='vision'></section>

        <section className='UHTVision'>
            <div className='container'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <h1 className='vision-title'data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="300">Vision</h1>
                                </div>
                           </div>
                           <div className='row align-items-center'>
                                <div className='col-md-7 text-center'>
                                <img src={require('../images/image-vision.jpg')} className="img-fluid" alt=""data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="400"/>
                                </div>
                                <div className='col-md-5'>
                                    <h2 className='vision-subtitle'data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="500">From Data to Impact: <br/> The AI Powered Proof-of-Health Revolution</h2>
                                    <p className='content-text'data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="600">UHT is revolutionizing preventive healthcare   with AI-driven personalization, intelligent insights, and real-time rewards—creating a future where proactive health is effortless, accessible, and rewarding.
                                    </p>
                                </div>
                                
                           </div>
            </div>
        </section>
    </>
  );
}


export default UHTVision;
