import React, { useState, useEffect } from 'react';

import './UHTPartners.css';

function UHTPartners() {

    const [isDesktop, setIsDesktop] = useState(true);

    useEffect(() => {
      const handleResize = () => {
        setIsDesktop(window.innerWidth > 980);  // You can adjust the width threshold as needed
      };
  
      handleResize();  // Check initial screen size
      window.addEventListener('resize', handleResize);
  
      return () => window.removeEventListener('resize', handleResize); // Clean up on unmount
    }, []);

  return (
    <>
        <section className='space-div-block' id='our-partners'></section>

        <section className='UHTPartners'>
            <div className='container'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <h1 className='partners-title' data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="300">Partners </h1>
                                    <h2 className='partners-subtitle' data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="500">Our Ecosystem Partners & Investors</h2>
                                    <p className='content-text mb-5' data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="600">
                                    UHT collaborates with industry leaders in blockchain, healthcare, and e-commerce to create a seamless and impactful Proof-of-Health ecosystem.
                                            </p>
                                </div>
                           </div>
                           <div className='row align-items-center'>
                                <div className='col-6 col-md-3 mt-3'>
                                    <img src={require('../images/logo-partner-1.jpg')} className='img-fluid' alt=''  data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="300"/>
                                </div>

                                <div className='col-6 col-md-3 mt-3'>
                                    <img src={require('../images/logo-partner-2.jpg')} className='img-fluid' alt=''  data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="400"/>
                                </div>

                                <div className='col-6 col-md-3 mt-3'>
                                    <img src={require('../images/logo-partner-3a.jpg')} className='img-fluid' alt='' data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="500"/>
                                </div>

                                <div className='col-6 col-md-3 mt-3'>
                                    <img src={require('../images/logo-partner-4.jpg')}  className='img-fluid' alt='' data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="600"/>
                                </div>

                                <div className='col-6 col-md-3 mt-3'>
                                    <img src={require('../images/logo-partner-5.jpg')} className='img-fluid' alt='' data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="300"/>
                                </div>

                                <div className='col-6 col-md-3 mt-3'>
                                    <img src={require('../images/logo-partner-6.jpg')}  className='img-fluid' alt='' data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="400"/>
                                </div>

                                <div className='col-6 col-md-3 mt-3'>
                                    <img src={require('../images/logo-partner-7.jpg')}  className='img-fluid' alt='' data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="500"/>
                                </div>

                                <div className='col-6 col-md-3 mt-3'>
                                    <img src={require('../images/logo-partner-8.jpg')}  className='img-fluid' alt='' data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="600"/>
                                </div>
                                
                           </div>
            </div>
        </section>
    </>
  );
}


export default UHTPartners;
