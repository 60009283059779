import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, EffectFade, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/effect-fade';
import './UHTValue.css';

function UHTValue() {

    const [isDesktop, setIsDesktop] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 980);  // You can adjust the width threshold as needed
    };

    handleResize();  // Check initial screen size
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize); // Clean up on unmount
  }, []);

  return (
    <>
        <section className='space-div-block' id='value'></section>

        <section className='UHTValue'>
            <div className='container'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <h1 className='value-title' data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="300">Transforming Health into Value</h1>
                                </div>
                           </div>

                           <div className='row align-items-center'>
                                <div className='col-md-12 text-center'>
                                <Swiper
                                    spaceBetween={0}
                                    slidesPerView={1}
                                    effect="fade"
                                    fadeEffect={{ crossFade: true }} // Smooth fade effect
                                    navigation={{
                                      nextEl: '.custom-next',
                                      prevEl: '.custom-prev',
                                    }}
                                    autoplay={{
                                      delay: 3000, // Auto slide every 3 seconds
                                      disableOnInteraction: false,
                                    }}
                                    modules={[Navigation, EffectFade, Autoplay]}
                                    loop={true}
                                    speed={1000} // Adjust speed for smoother fade transitions
                                    className="custom-swiper"
                                    >
                                    <SwiperSlide>
                                        <div className='row align-items-center'>
                                                <div className='col-md-5'>
                                                    <h2 className='value-subtitle' data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="500">Intelligent Health Data Sharing</h2>
                                                    <p className='content-text mb-0' data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="600">With our AI-enabled consent framework, <span>you decide when and how to share your health data.</span> Empower research, access better services, or explore personalized insurance—on your terms.</p>
                                                </div>
                                                <div className='col-md-7 text-center'>
                                                <img src={require('../images/slider-Health-Data.jpg')} className="img-fluid" alt=""  data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="500"/>
                                                </div>
                                        </div>
                                    </SwiperSlide>

                                    <SwiperSlide>
                                        <div className='row align-items-center'>
                                                <div className='col-md-5'>
                                                    <h2 className='value-subtitle' data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="500">Smarter Rewards for a Healthier Life</h2>
                                                    <p className='content-text mb-0' data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="600">Our AI-driven incentive system <span>dynamically adapts to your unique habits— motivating</span> you with rewards for workouts, nutrition goals, and mindfulness practices.</p>
                                                </div>
                                                <div className='col-md-7 text-center'>
                                                <img src={require('../images/slider-Gamified-Preventive-Healthcare.jpg')} className="img-fluid" alt=""  data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="500"/>
                                                </div>
                                        </div>
                                    </SwiperSlide>

                                    <SwiperSlide>
                                        <div className='row align-items-center'>
                                                <div className='col-md-5'>
                                                    <h2 className='value-subtitle' data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="500">AI Meets Web3 Commerce</h2>
                                                    <p className='content-text mb-0' data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="600">Earn and redeem UHT tokens across <span>300+ wellness brands</span>, from fitness programs to personalized health services—creating a seamless loop of healthier living and spending.</p>
                                                </div>
                                                <div className='col-md-7 text-center'>
                                                <img src={require('../images/slider-Health-Marketplace.jpg')} className="img-fluid" alt=""  data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="500"/>
                                                </div>
                                        </div>
                                    </SwiperSlide>

                                    <SwiperSlide>
                                        <div className='row align-items-center'>
                                                <div className='col-md-5'>
                                                    <h2 className='value-subtitle' data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="500">AI-Powered Preventive Health</h2>
                                                    <p className='content-text mb-0' data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="600">UHT’s intelligent AI continuously analyzes wearable data, lifestyle patterns, and health history, <span>providing real-time, personalized health insights</span> that adapt to your evolving lifestyle.</p>
                                                </div>
                                                <div className='col-md-7 text-center'>
                                                <img src={require('../images/slider-Decentralized-Science.jpg')} className="img-fluid" alt=""  data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="500"/>
                                                </div>
                                        </div>
                                    </SwiperSlide>
                                
                                    {/* Additional SwiperSlide components can go here */}

                                    <div className="custom-prev"><i class="bi bi-arrow-left"></i></div>
                                    <div className="custom-next"><i class="bi bi-arrow-right"></i></div>
                                    </Swiper>
                                </div>
                           </div>

                           
            </div>
        </section>
    </>
  );
}


export default UHTValue;
