import React, { useState, useEffect } from 'react';

import './UHTAdvisors.css';

function UHTAdvisors() {

    const [isDesktop, setIsDesktop] = useState(true);

    useEffect(() => {
      const handleResize = () => {
        setIsDesktop(window.innerWidth > 980);  // You can adjust the width threshold as needed
      };
  
      handleResize();  // Check initial screen size
      window.addEventListener('resize', handleResize);
  
      return () => window.removeEventListener('resize', handleResize); // Clean up on unmount
    }, []);

  return (
    <>
        {/* <section className='space-div-block' id='our-advisors'></section> */}

        <section className='UHTAdvisors'>
            <div className='container'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <h1 className='advisors-title' data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="300">Advisors</h1>
                                    {/* <h2 className='advisors-subtitle' data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="500">Our Ecosystem advisors & Investors</h2>
                                    <p className='content-text mb-5' data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="600">
                                    UHT collaborates with industry leaders in blockchain, healthcare, and e-commerce to create a seamless and impactful Proof-of-Health ecosystem.
                                            </p> */}
                                </div>
                           </div>
                           <div className='row align-items-center'>
                               
                                
                           </div>
            </div>

            <div className="container partner-section">
       
          <div className="w-layout-vflex1 flex-block-5">
            <div className="w-layout-hflex1 advisor-wrapper core">
              <div className="advisor-item core">
                <div className="advisor yat-siu">
                  <a href="https://www.linkedin.com/in/yatsiu/" className="linked-in-core" style={{cursor: 'pointer'}} target="blank" > </a>
                </div>
                <div className="w-layout-vflex1 flex-block-3">
                  <p className="paragraph black center-allign semi-large">
                    <strong>Yat Siu</strong>
                  </p>
                  <p className="paragraph black center-allign">
                    Co-founder &amp; Chairman
                    <br />
                    Animoca Brands
                  </p>
                </div>
              </div>
              <div className="advisor-item core">
                <div className="advisor vishal-gondal">
                   <a className="linked-in-core" style={{cursor: 'pointer'}}  target="blank" href="https://www.linkedin.com/in/vishalgondal/"> </a>
                </div>
                <div className="w-layout-vflex1 flex-block-3">
                  <p className="paragraph black center-allign semi-large">
                    <strong>Vishal Gondal</strong>
                  </p>
                  <p className="paragraph black center-allign">
                    Founder &amp; CEO
                    <br />
                    GOQii Foundation
                  </p>
                </div>
              </div>
              <div className="advisor-item core">
                <div className="advisor zeneca">
                   <a className="linked-in-core" style={{cursor: 'pointer'}}  target="blank" href="https://x.com/Zeneca"> </a>
                </div>
                <div className="w-layout-vflex1 flex-block-3">
                  <p className="paragraph black center-allign semi-large">
                    <strong>Zeneca</strong>
                  </p>
                  <p className="paragraph black center-allign">
                    Founder
                    <br />
                    Zen Academy
                  </p>
                </div>
              </div>
            </div>
            <div className="w-layout-hflex1 advisor-wrapper">
              <div className="advisor-item">
                <a href="https://www.linkedin.com/in/agastya-samat-090a16148/"  target="blank" className="w-inline-block">
                  <div className="advisor agatya-saman">
                    <div className="investors-logo goqii"></div>
                    <a className="linkedin" style={{cursor: 'pointer'}}  target="blank" href="https://www.linkedin.com/in/agastya-samat-090a16148/"> </a>
                  </div>
                </a>
                <div className="w-layout-vflex1 flex-block-3">
                  <p className="paragraph black center-allign semi-large">
                    <strong className="advisor-name">Agastya Samat</strong>
                  </p>
                  <p className="paragraph black center-allign">
                    Director
                    <br />
                    Force of Good Foundation
                  </p>
                </div>
              </div>
              <div className="advisor-item">
                <a href="https://www.linkedin.com/in/mohamed-ezeldin-07859339/" className="w-inline-block"  target="blank">
                  <div className="advisor mohammed-ezeldin">
                    <div className="investors-logo goqii"></div>
                    <a className="linkedin" style={{cursor: 'pointer'}}  target="blank" href="https://www.linkedin.com/in/mohamed-ezeldin-07859339/"> </a>
                  </div>
                </a>
                <div className="w-layout-vflex1 flex-block-3">
                  <p className="paragraph black center-allign semi-large">
                    <strong className="advisor-name">Mohamed Ezeldin</strong>
                  </p>
                  <p className="paragraph black center-allign">
                    Head of Tokenomics
                    <br />
                    Animoca Brands
                  </p>
                </div>
              </div>
              <div className="advisor-item">
                <a href="https://www.linkedin.com/in/kavita-gupta-71a4352/"  target="blank" className="w-inline-block">
                  <div className="advisor kavita-gupta">
                    <div className="investors-logo goqii"></div>
                    <a className="linkedin" style={{cursor: 'pointer'}}  target="blank" href="https://www.linkedin.com/in/kavita-gupta-71a4352/"> </a>
                  </div>
                </a>
                <div className="w-layout-vflex1 flex-block-3">
                  <p className="paragraph black center-allign semi-large">
                    <strong className="advisor-name">Kavita Gupta</strong>
                  </p>
                  <p className="paragraph black center-allign">
                    Founding Managing Partner
                    <br />
                    Delta Blockchain Fund &amp;
                    <br />
                    Fintech.TV
                  </p>
                </div>
              </div>
              <div className="advisor-item">
                <a href="https://www.linkedin.com/in/tarunkatial/" target="blank" className="w-inline-block">
                  <div className="advisor tarum-katial">
                    <div className="investors-logo goqii"></div>
                    <a className="linkedin" style={{cursor: 'pointer'}}  target="blank" href="https://www.linkedin.com/in/tarunkatial/ "> </a>
                  </div>
                </a>
                <div className="w-layout-vflex1 flex-block-3">
                  <p className="paragraph black center-allign semi-large">
                    <strong className="advisor-name">Tarun Katial</strong>
                  </p>
                  <p className="paragraph black center-allign">
                    Founder &amp; CEO
                    <br />
                    Coto
                  </p>
                </div>
              </div>
            </div>
            <div className="w-layout-hflex1 advisor-wrapper">
              <div className="advisor-item">
                <a href="https://www.linkedin.com/in/lauren-s-4ba188/" target="blank" className="w-inline-block">
                  <div className="advisor lauren-selig">
                    <div className="investors-logo goqii"></div>
                    <a className="linkedin" style={{cursor: 'pointer'}}  target="blank" href="https://www.linkedin.com/in/lauren-s-4ba188/ "> </a>
                  </div>
                </a>
                <div className="w-layout-vflex1 flex-block-3">
                  <p className="paragraph black center-allign semi-large">
                    <strong className="advisor-name">Lauren Selig</strong>
                  </p>
                  <p className="paragraph black center-allign">
                    Founder &amp; CEO
                    <br />
                    Shake and Bake
                    <br />
                    Productions
                  </p>
                </div>
              </div>
              <div className="advisor-item">
                <a href="https://www.linkedin.com/in/manish-agarwal-6586482/" target="blank" className="w-inline-block">
                  <div className="advisor manish-agarwal">
                    <div className="investors-logo goqii"></div>
                    <a className="linkedin" style={{cursor: 'pointer'}}  target="blank" href="https://www.linkedin.com/in/manish-agarwal-6586482/"> </a>
                  </div>
                </a>
                <div className="w-layout-vflex1 flex-block-3">
                  <p className="paragraph black center-allign semi-large">
                    <strong className="advisor-name">Manish Agarwal</strong>
                  </p>
                  <p className="paragraph black center-allign">
                    Council Member
                    <br />
                    Kratos Gaming Network
                    <br />
                    (KGen)
                  </p>
                </div>
              </div>
              <div className="advisor-item">
                <a href="https://www.linkedin.com/in/manishdureja/" target="blank" className="w-inline-block">
                  <div className="advisor manish-dureja">
                    <div className="investors-logo goqii"></div>
                    <a className="linkedin" style={{cursor: 'pointer'}}  target="blank" href="https://www.linkedin.com/in/manishdureja/"> </a>
                  </div>
                </a>
                <div className="w-layout-vflex1 flex-block-3">
                  <p className="paragraph black center-allign semi-large">
                    <strong className="advisor-name">Manish Dureja</strong>
                  </p>
                  <p className="paragraph black center-allign">
                    Ex MD &amp; CEO
                    <br />
                    Intermiles
                  </p>
                </div>
              </div>
              <div className="advisor-item">
                <a href="https://www.linkedin.com/in/manoj-narender-madnani/"  target="blank" className="w-inline-block">
                  <div className="advisor manoj-narenden-madnadi">
                    <div className="investors-logo goqii"></div>
                    <a className="linkedin" style={{cursor: 'pointer'}}  target="blank" href="https://www.linkedin.com/in/manoj-narender-madnani/"> </a>
                  </div>
                </a>
                <div className="w-layout-vflex1 flex-block-3">
                  <p className="paragraph black center-allign semi-large">
                    <strong className="advisor-name">
                    Manoj Narender Madnani
                    </strong>
                  </p>
                  <p className="paragraph black center-allign">
                    MD
                    <br />
                    Marathon (EMEA)
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        </section>
    </>
  );
}


export default UHTAdvisors;
