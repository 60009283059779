import React from 'react';
import './UHTNavbar.css';

function UHTNavbar() {
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <nav className="navbar sticky-top navbar-expand-lg bg-body-tertiary UHTNavbar">
        <div className="container UHTNavbar-inner">
          {/* Logo on the left */}
          <a className="navbar-brand" href="####">
            <img src={require('../images/uht-logo.png')} className="img-fluid" alt="Logo" />
          </a>

          {/* Navbar Toggler for mobile view */}
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>

          {/* Navbar items on the right */}
          <div className="collapse navbar-collapse justify-content-end" id="navbarText">
            <ul className="navbar-nav mb-2 mb-lg-0">
              <li className="nav-item">
                <a
                  className="nav-link-menu link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover"
                  onClick={(e) => {
                    e.preventDefault();
                    scrollToSection('home');
                  }}
                  href="####"
                >
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link-menu link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover"
                  href="https://uht.gitbook.io/universal-health-token/" target="blank"
                >
                  Whitepaper
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link-menu link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover"
                  onClick={(e) => {
                    e.preventDefault();
                    scrollToSection('our-partners');
                  }}
                  href="####"
                >
                  Our Partners
                </a>
              </li>
              {/* <li className="nav-item">
                <a
                  className="nav-link-menu link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover"
                  href="####"
                >
                  FoG Foundation
                </a>
              </li> */}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

export default UHTNavbar;
