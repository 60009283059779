import React, { useState, useEffect } from 'react';
import './UHTBanner.css';

function UHTBanner() {

  const [isDesktop, setIsDesktop] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 980);  // You can adjust the width threshold as needed
    };

    handleResize();  // Check initial screen size
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize); // Clean up on unmount
  }, []);

  return (
    <>
      <header className="UHTBanner" id="home">
            <div className='bg-image' data-aos={isDesktop ? 'fade-in' : undefined}  data-aos-delay="100"></div>
            <div className="container">
                <div className="row align-items-center">
                    <div className='col-md-7 pe-md-5'>
                        <h1 className='title' data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="400">The AI driven <br/>Proof-of-Health Protocol is here.</h1>
                        <h2 className='subtitle' data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="500">UHT is is redefining healthcare—making preventive care intelligent, personalized, and rewarding. By combining real-time data analysis with on-chain innovation, we empower a new era of accessible, data-driven health experiences.</h2>
                        <button type="button" className="btn btn-success btn-book-demo me-3" data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="600" >Learn More</button>
                        <button type="button" className="btn btn-success btn-saving-calculator" data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="600" data-bs-toggle="modal" data-bs-target="#uhtConnetWalletModal">Connect Wallet</button>
                        <br/>
                        <a type="button" className="btn btn-success btn-goqii-anchor mt-3 banner-goqii-btn" href='https://goqii.com/in-en' target='blank' data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="600">
                         <img src={require('../images/goqii-logo-white.png')} className='me-1' style={{height:"16px"}} alt="" /><span className='pt-2'>- Our Anchor Partner</span></a>
                    </div>
                    
                </div>
            </div>
      </header>


      
    </>
  );
}


export default UHTBanner;
